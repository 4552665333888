<template>
  <div>
    <b-card>
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="companies/GET_COMPANY_USERS"
        ref="usersTable"
        :dataURLParams="{ companyId: $route.params.companyId }"
      >
        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="data.item.selected"
            class="mr-0 mt-50 ml-1"
            name="is-rtl"
            inline
            value="1"
            unchecked-value="0"
          />
        </template>
        <template #cell(edit)="data">
          <b-form-checkbox
            class="mr-0 mt-50 ml-1"
            name="is-rtl"
            switch
            inline
            v-model="data.item.ability.manage"
          />
        </template>
        <template #cell(read)="data">
          <b-form-checkbox
            class="mr-0 mt-50 ml-1"
            name="is-rtl"
            switch
            inline
            v-model="data.item.ability.read"
          />
        </template>
        <template #cell(delete)="data">
          <feather-icon
            style="color:red !important;"
            icon="XIcon"
            size="16"
            class="align-middle text-body ml-1"
            :data-id="data.item.id"
          />
        </template>
      </advanced-table>
      <b-button @click="saveUsers" class="ml-1" variant="primary">{{
        $t('save')
      }}</b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';
import { dangerToast, successToast } from '@/utils/toast';
export default {
  components: {
    AdvancedTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
  },
  data() {
    return {
      selected: [],
      tableColumns: [
        { key: 'selected', label: '', sortable: true },
        { key: 'firstName', label: i18n.tc('firstname'), sortable: true },
        { key: 'lastName', label: i18n.tc('lastname'), sortable: true },
        { key: 'email', label: i18n.tc('email'), sortable: true },
        /*{ key: 'edit', label: i18n.tc('edit_devices') },
        { key: 'read', label: i18n.tc('read_devices') },*/
      ],
    };
  },
  methods: {
    saveUsers() {
      try {
        this.$store.dispatch('companies/SAVE_USERS', {
          companyId: this.$route.params.companyId,
          users: this.$refs.usersTable.data.map(u => {
            return { id: u.id, ability: u.ability, selected: u.selected };
          }),
        });

        successToast(this.$toast, i18n.t('success'), i18n.t('update_success'));
      } catch (e) {
        dangerToast(
          this.$toast,
          i18n.t('erorr'),
          i18n.t('general_error_description'),
        );
      }
    },
    isActionActive(abilities, action) {
      let isActive = false;
      abilities.forEach(a => {
        if (a.action == action) {
          isActive = true;
          return;
        }
      });
      return isActive;
    },
  },
};
</script>

<style></style>
